import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    admin_username: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://treadgroceryservices.shop/admin/phone_banao/admin_login.php', formData)
      .then(response => {
        if (response.data.status === 'success') {
          // Save the admin_username in local storage
          localStorage.setItem('admin_username', formData.admin_username);
          toast.success('Login successful!');
          window.location.href = '/admin';
          // You can also redirect or show a success message here
        } else {
          toast.error(`Login failed: ${response.data.message}`);
        }
      })
      .catch(error => {
        toast.error('There was an error! Please try again.');
        console.error('There was an error!', error);
      });
  };

  return (
    <div className='container' style={{ marginTop: '30px' }}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="admin_username">Username</label>
          <input
            type="text"
            className="form-control"
            id="admin_username"
            placeholder="Username"
            name="admin_username"
            value={formData.admin_username}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;
