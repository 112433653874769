import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./UserLogin.css";
import Header from "./component/header";
import Footer from "./component/footer";

function UserLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://treadgroceryservices.shop/admin/phone_banao/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "success") {
        setSuccess(data.message);
        setError("");

        // Save user details to local storage
        localStorage.setItem("username", data.data.username);
        localStorage.setItem("phone_no", data.data.phone_no);
        localStorage.setItem("address", data.data.address);
        window.location.href = '/';
      } else {
        setError(data.message);
        setSuccess("");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("Failed to submit form. Please try again later.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img
              src="https://www.yaantra.com/images/in-30min.png"
              alt="Side Image"
              className="side-image"
            />
          </div>
          <div className="col-md-6 signup-form">
            <center>
              <h1 className="mb-4">Log In</h1>
            </center>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Log in
              </button>
            </form>
            <div className="mt-3">
              <p>
                I don't have an account, go{" "}
                <Link to="/signup" className="text-secondary">
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserLogin;
