import React from 'react'
import Header from './component/header'
import Footer from './component/footer'
import { Link } from 'react-router-dom'
import phone from "../img/Mobile-Phone-PNG-HD.png"
import tablet from "../img/tablet-png-32907.png"
import smartwatch from "../img/smartwatch.png"
import earpod from "../img/earpod.png"
import brokenscreen from "../img/broken-screen.png"
import battery from "../img/battery.png"
import mic from "../img/mic.png"
import speaker from "../img/speaker.png"
import camera from '../img/camera.png'

const Appointment = () => {
  return (
    <div id='Appointment'>
    <Header/>
<div className='appointment'>
<div className='container'>
        <h3 className='section-title'>
            <strong>Appointment for Device</strong>
        </h3>
        <div className='row'>
        <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/brands">
               <div className='appoint-Device'>
                <img src={phone}></img>
               </div>
               
                <h6>Smartphone</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/brands">
               <div className='appoint-Device'>
                <img src={tablet}></img>
               </div>
               
                <h6>Tablet</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/brands">
               <div className='appoint-Device'>
                <img src={smartwatch}></img>
               </div>
               
                <h6>Smartwatch</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/brands">
               <div className='appoint-Device'>
                <img src={earpod}></img>
               </div>
               
                <h6>Earpod</h6>
               </Link>
            </div>
        </div>


        <h3 className='section-title'>
         <strong>Services Available</strong>
        </h3>
        <div className='row'>
        <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/mobile_repair">
               <div className='appoint-Device'>
                <img src={brokenscreen}></img>
               </div>
               
                <h6>Broken-screen</h6>
               </Link>
            </div>

            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/mobile_repair">
               <div className='appoint-Device'>
                <img src={battery}></img>
               </div>
               
                <h6>Bettery</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link>
               <div className='appoint-Device'>
                <img src={mic}></img>
               </div>
               
                <h6>Mic</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link>
               <div className='appoint-Device'>
                <img src={speaker}></img>
               </div>
               
                <h6>Mic</h6>
               </Link>
            </div>
            <div className='col-4 col-lg-2  text-center appoint-Device_content'>
               <Link to="/mobile_repair">
               <div className='appoint-Device'>
                <img src={camera}></img>
               </div>
               
                <h6>Camera</h6>
               </Link>
            </div>
        </div>
</div>
</div>
      <Footer/>
    </div>
  )
}

export default Appointment
