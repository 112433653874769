import React, { useState } from "react";
import Sidebar from "../../components/side_bar";
import Fixed_header from "../../components/fixed_header";

const Brand = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productBrandImage, setProductBrandImage] = useState("");
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleCreate = () => {
    console.log("Product Created:", { productName, productBrand, productBrandImage });
    handleClose();
  };

  const handleEditShow = (product) => {
    setCurrentProduct(product);
    setProductName(product.name);
    setProductBrand(product.brand);
    setProductBrandImage(product.image);
    setShowEditModal(true);
  };
  const handleEditClose = () => setShowEditModal(false);
  const handleEdit = () => {
    console.log("Product Updated:", { productName, productBrand, productBrandImage });
    handleEditClose();
  };

  const handleDeleteShow = (product) => {
    setCurrentProduct(product);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDelete = () => {
    console.log("Product Deleted:", currentProduct);
    handleDeleteClose();
  };

  const handleViewShow = (product) => {
    setCurrentProduct(product);
    setShowViewModal(true);
  };
  const handleViewClose = () => setShowViewModal(false);

  const products = [
    {
      id: 1,
      name: "Mobile",
      brand: "Apple, Samsung, Xiaomi, Oppo, Vivo, OnePlus, Google, Huawei, Sony, Realme",
      image: "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Smartphone.png?v=1706646691",
      createDate: "2022-11-25",
      updateDate: "2024-03-05",
    },
    {
      id: 2,
      name: "Tablet",
      brand: "Apple, Samsung, Huawei, Lenovo, Microsoft",
      image: "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/tablet.png?v=1706646659",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 3,
      name: "Laptop",
      brand: "Apple, Dell, HP, Lenovo, Asus, Acer, Microsoft, MSI, Razer, Samsung",
      image: "https://t3.ftcdn.net/jpg/01/65/68/40/360_F_165684079_HBdjlE5yM4sbSPBKb0qp0cYSfeKdosYv.jpg",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 4,
      name: "Smartwatch",
      brand: "Apple, Samsung, Garmin, Fitbit, Huawei, Fossil, Amazfit, Suunto, Withings, TicWatch",
      image: "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/smartwatch.png?v=1706646626",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 5,
      name: "Earbuds",
      brand: "Apple, Samsung, Sony, Bose, Sennheiser, Jabra, Beats, Anker, JBL, Google",
      image: "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Earpods.png?v=1706645530",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
  ];
  

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head-between flex-wrap gap g-2">
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title">Products</h2>
                    </div>
                    <div className="nk-block-head-content">
                      <ul className="d-flex">
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary btn-md d-md-none"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary d-none d-md-inline-flex"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Product</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="nk-block">
                    <div className="card data-table-card">
                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div className="dataTable-top">
                          <div className="dataTable-dropdown">
                            <label>
                              <select className="dataTable-selector">
                                <option value="5">5</option>
                                <option value="10" selected>
                                  10
                                </option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                              </select>
                              Per page
                            </label>
                          </div>
                          <div className="dataTable-search">
                            <input
                              className="dataTable-input"
                              placeholder="Search..."
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="dataTable-container table-responsive">
                          <table
                            className="datatable-init table dataTable-table"
                            data-nk-container="table-responsive"
                          >
                            <thead className="table-light">
                              <tr>
                                <th
                                  className="tb-col tb-col-check"
                                  data-sortable="false"
                                  style={{ width: "5%" }}
                                ></th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "30%" }}
                                >
                                  <span className="overline-title">
                                    Product Image
                                  </span>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "20%" }}
                                >
                                  <span className="overline-title">
                                    Product Name
                                  </span>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "20%" }}
                                >
                                  <a href="#" className="dataTable-sorter">
                                    <span className="overline-title">
                                      Brand Name
                                    </span>
                                  </a>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "20%" }}
                                >
                                  <a href="#" className="dataTable-sorter">
                                    <span className="overline-title">
                                      Create Date
                                    </span>
                                  </a>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable="false"
                                  style={{ width: "20%" }}
                                >
                                  <span className="overline-title">
                                    Update Date
                                  </span>
                                </th>
                                <th
                                  className="tb-col tb-col-end"
                                  data-sortable="false"
                                  style={{ width: "5%" }}
                                >
                                  <span className="overline-title">
                                    Actions
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {products.map((product) => (
                                <tr key={product.id}>
                                  <td className="tb-col tb-col-check">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                      />
                                    </div>
                                  </td>
                                  <td className="tb-col">
                                    <div className="media-group">
                                      <div className="media media-middle">
                                        <img
                                          src={product.image}
                                          alt="product"
                                        />
                                      </div>
                                      <div className="media-text">
                                        <a href="#" className="title"></a>
                                        <div className="d-sm-none">
                                          <span>300</span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="tb-col">
                                    <span className="">
                                      {product.name}
                                    </span>
                                  </td>
                                  <td className="tb-col">
                                    <span className="">
                                      {product.brand}
                                    </span>
                                  </td>
                                  <td className="tb-col">
                                    <span className="small">{product.createDate}</span>
                                  </td>
                                  <td className="tb-col">
                                    <span className="small">{product.updateDate}</span>
                                  </td>
                                  <td className="tb-col tb-col-end">
                                    <div className="d-flex gap g-2">
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          className="btn btn-sm btn-icon btn-zoom me-n1"
                                        >
                                          <em className="icon ni ni-more-v"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <button
                                                className="dropdown-item"
                                                onClick={() => handleViewShow(product)}
                                              >
                                                <em className="icon ni ni-eye"></em>
                                                <span>View Details</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item"
                                                onClick={() => handleEditShow(product)}
                                              >
                                                <em className="icon ni ni-pen"></em>
                                                <span>Edit</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="dropdown-item"
                                                onClick={() => handleDeleteShow(product)}
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="dataTable-bottom">
                          <div className="dataTable-info">
                            <div className="dataTable-pagination">
                              <ul className="dataTable-pagination-list">
                                <li className="pager">
                                  <a href="#" className="active">
                                    1
                                  </a>
                                </li>
                                <li className="pager">
                                  <a href="#">2</a>
                                </li>
                                <li className="pager">
                                  <a href="#">3</a>
                                </li>
                                <li className="pager">
                                  <a href="#">4</a>
                                </li>
                                <li className="pager">
                                  <a href="#">5</a>
                                </li>
                                <li className="pager">
                                  <a href="#">6</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Create Modal */}
                  <div className={`modal ${showModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showModal ? "block" : "none" }}>
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Create Product</h5>
                          <button type="button" className="close" onClick={handleClose}>
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="productName">Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="productName"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="productBrand">Product Brand</label>
                            <input
                              type="text"
                              className="form-control"
                              id="productBrand"
                              value={productBrand}
                              onChange={(e) => setProductBrand(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="productBrandImage">Product Brand Image</label>
                            <input
                              type="text"
                              className="form-control"
                              id="productBrandImage"
                              value={productBrandImage}
                              onChange={(e) => setProductBrandImage(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                          </button>
                          <button type="button" className="btn btn-primary" onClick={handleCreate}>
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Edit Modal */}
                  <div className={`modal ${showEditModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showEditModal ? "block" : "none" }}>
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Edit Product</h5>
                          <button type="button" className="close" onClick={handleEditClose}>
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="editProductName">Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="editProductName"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="editProductBrand">Product Brand</label>
                            <input
                              type="text"
                              className="form-control"
                              id="editProductBrand"
                              value={productBrand}
                              onChange={(e) => setProductBrand(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="editProductBrandImage">Product Brand Image</label>
                            <input
                              type="text"
                              className="form-control"
                              id="editProductBrandImage"
                              value={productBrandImage}
                              onChange={(e) => setProductBrandImage(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={handleEditClose}>
                            Close
                          </button>
                          <button type="button" className="btn btn-primary" onClick={handleEdit}>
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Delete Modal */}
                  <div className={`modal ${showDeleteModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showDeleteModal ? "block" : "none" }}>
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Delete Product</h5>
                          <button type="button" className="close" onClick={handleDeleteClose}>
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>Are you sure you want to delete this product?</p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={handleDeleteClose}>
                            Close
                          </button>
                          <button type="button" className="btn btn-danger" onClick={handleDelete}>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* View Modal */}
                  <div className={`modal ${showViewModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showViewModal ? "block" : "none" }}>
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">View Product</h5>
                          <button type="button" className="close" onClick={handleViewClose}>
                            <span>&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="viewProductName">Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="viewProductName"
                              value={currentProduct?.name}
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="viewProductBrand">Product Brand</label>
                            <input
                              type="text"
                              className="form-control"
                              id="viewProductBrand"
                              value={currentProduct?.brand}
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="viewProductBrandImage">Product Brand Image</label>
                            <input
                              type="text"
                              className="form-control"
                              id="viewProductBrandImage"
                              value={currentProduct?.image}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={handleViewClose}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;

