
import Sidebar from "../../components/side_bar";
import FixedHeader from "../../components/fixed_header";
import { Link } from "react-router-dom";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Model = () => {
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <FixedHeader />
          <div className="nk-content">
          <div class="container">
    <div class="nk-content-inner">
        <div class="nk-content-body">
            <div class="nk-block-head">
                <div class="nk-block-head-between flex-wrap gap g-2">
                    <div class="nk-block-head-content">
                        <h2 class="nk-block-title">Models</h2>
                        <nav>
                            <ol class="breadcrumb breadcrumb-arrow mb-0">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">User Manage</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="nk-block-head-content">
                        <ul class="d-flex">
                            <li>
                                <Link to="/add_model" class="btn btn-md d-md-none btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#addUserModal"><em class="icon ni ni-plus"></em><span>Add Model</span></Link>
                            </li>
                            <li>
                            <Link to="/add_model" class="btn btn-primary d-none d-md-inline-flex" data-bs-toggle="modal"
                                    data-bs-target="#addUserModal"><em class="icon ni ni-plus"></em><span>Add Model</span></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="nk-block">
                <div class="row g-gs">
{/* =============================card */}
<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image="/static/images/cards/contemplative-reptile.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lizards are a widespread group of squamate reptiles, with over 6,000
          species, ranging across all continents except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
{/* =================================card */}

                    <div class="col-sm-6 col-xl-4 col-xxl-3">
                        <div class="card text-center h-100">
                            <div class="card-body">
                                <div class="media media-xxl media-middle media-circle">
                                    <img src="https://html.nioboard.themenio.com/images/avatar/a.jpg" alt="user"/>
                                </div>
                                <div class="mt-1 mb-4">
                                    <a href="@@/URL/user-manage/user-profile.html" class="mb-1 h5">Florenza Desporte</a>
                                    <div class="small">Administrator</div>
                                </div>
                                <div class="row g-gs justify-content-center">
                                    <div class="col-5">
                                        <div class="h5 mb-1">2574</div>
                                        <div class="small">Followers</div>
                                    </div>
                                    <div class="col-5">
                                        <div class="h5 mb-1">78<span>K</span></div>
                                        <div class="small">Following</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown position-absolute top-0 end-0 p-3">
                                <a href="#" class="btn btn-sm btn-icon btn-zoom" data-bs-toggle="dropdown"><em
                                        class="icon ni ni-more-v"></em></a>
                                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                    <div class="dropdown-content py-1">
                                        <ul class="link-list link-list-hover-bg-primary link-list-md">
                                            <li><a href="https://html.nioboard.themenio.com/user-manage/user-edit.html"><em
                                                        class="icon ni ni-edit"></em><span>Edit</span></a></li>
                                            <li><a href="https://html.nioboard.themenio.com/user-manage/user-edit.html"><em
                                                        class="icon ni ni-trash"></em><span>Delete</span></a></li>
                                            <li><a href="https://html.nioboard.themenio.com/user-manage/user-profile.html"><em
                                                        class="icon ni ni-eye"></em><span>View Details</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="card mt-5">
                <div class="card-body d-flex flex-wrap align-items-center justify-content-between">
                    <nav>
                        <ul class="pagination pagination-s1">
                            <li class="page-item disabled"><a class="page-link" href="#"><em
                                        class="ni ni-chevron-left icon"></em></a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#"><em class="ni ni-chevron-right icon"></em></a>
                            </li>
                        </ul>
                    </nav>
                    <span class="smaller py-2">Showing 1 to 12 of 50 entries</span>
                </div>
            </div>
        </div>
    </div>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Model;
