
import Header from './component/header'
import { Link } from 'react-router-dom'
import axios from "axios";
import React, { useState, useEffect } from "react";

const Brands = () => {
    const [showModal, setShowModal] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [brandImage, setBrandImage] = useState(null);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
    useEffect(() => {
        fetchBrands();
      }, []);
    
      const fetchBrands = async () => {
        setLoading(true);
        try {
          const response = await axios.get("https://treadgroceryservices.shop/admin/phone_banao/get_brands.php");
          setBrands(response.data);
          setLoading(false);
        } catch (error) {
          setError("Error fetching brands. Please try again later.");
          setLoading(false);
        }
      };
  return (
    <div id='appointment'>
    <Header/>
    <div className='appointment'>
    <div className='container'>
        <h3 className='section-title'>
            <strong>Top Brands</strong>
        </h3>
        <div className='row'>

        {brands.map((brand) => (
             <div key={brand.brand_id} className='col-4 col-md-3 col-lg-2 col-sm-3'>
             <div className='top-brands'>
             <Link to="/brand_model">
             <img src={`https://treadgroceryservices.shop/admin/phone_banao/${brand.image}`}/>
             </Link>
                
             </div>
         </div>


))}
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/2e7cdc22-5a5f.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/cb96df6e-080f.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/406a512d-e8dd.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/2e7cdc22-5a5f.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/20922c34-8afc.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/0124cc45-3a6c.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            
            <div className='col-4 col-md-3 col-lg-2 col-sm-3'>
                <div className='top-brands'>
                <Link to="/brand_model">
                <img src='https://s3no.cashify.in/cashify/brand/img/xhdpi/1dcd7fda-0141.jpg?p=default&s=lg'/>
                </Link>
                   
                </div>
            </div>
            
            
        </div>
    </div>
    </div>
    
      
    </div>
  )
}

export default Brands
