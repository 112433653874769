import React from 'react';
import Sidebar from '../../components/side_bar';
import Fixed_header from '../../components/fixed_header';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
const Invoice = () => {
  return (
    <div class="nk-app-root">
    <div className='nk-main'>
    <Sidebar/>
    <div className='nk-wrap'>
{/* ==================================================================== */}
<Fixed_header/>


{/* =================================================================================== */}
<div class="nk-content">
  <div class="container">
  <div class="nk-content-inner">
  <div class="nk-content-body">
    <div class="nk-block-head">
      <div class="nk-block-head-between flex-wrap gap g-2">
        <div class="nk-block-head-content">
          <h2 class="nk-block-title">
            Invoice Preview
          </h2>
          <nav>
            <ol class="breadcrumb breadcrumb-arrow mb-0">
              <li class="breadcrumb-item">
                <a href="#">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">
                  Invoice
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Invoice Preview
              </li>
            </ol>
          </nav>
        </div>
        <div class="nk-block-head-content">
          <ul class="d-flex">
            <li>
              <a href="#" class="btn btn-md d-md-none btn-primary">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Create
                </span>
              </a>
            </li>
            <li>
              <a href="#" class="btn btn-primary d-none d-md-inline-flex">
                <em class="icon ni ni-plus">
                </em>
                <span>
                  Create Invoice
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nk-block">
      <div class="card">
        <div class="nk-invoice">
          <div class="nk-invoice-head flex-column flex-sm-row">
            <div class="nk-invoice-head-item mb-3 mb-sm-0">
              <div class="nk-invoice-brand mb-1">
                <a href="https://html.nioboard.themenio.com/index.html" class="logo-link">
                  <div class="logo-wrap">
                   
                  </div>
                </a>
              </div>
              <ul>
                <li>
                  info@company.com
                </li>
                <li>
                  (120) 456 789
                </li>
              </ul>
            </div>
            <div class="nk-invoice-head-item text-sm-end">
              <div class="h3">
                Invoices No: #790033
              </div>
              <ul>
                <li>
                  Invoice Date: 20 Oct 2022
                </li>
                <li>
                  Due Date: 26 Oct 2022
                </li>
              </ul>
            </div>
          </div>
          <div class="nk-invoice-head flex-column flex-sm-row">
            <div class="nk-invoice-head-item mb-3 mb-sm-0">
              <div class="h4">
                Invoice To
              </div>
              <ul>
                <li>
                  Lowell H. Dominguez
                </li>
                <li>
                  84 Spilman Street, London
                </li>
                <li>
                  United Kingdom
                </li>
                <li>
                  lowell@gmail.com
                </li>
              </ul>
            </div>
            <div class="nk-invoice-head-item text-sm-end">
              <div class="h4">
                Invoice From
              </div>
              <ul>
                <li>
                  Laralink Ltd
                </li>
                <li>
                  86-90 Paul Street, London
                </li>
                <li>
                  England EC2A 4NE
                </li>
                <li>
                  demo@gmail.com
                </li>
              </ul>
            </div>
          </div>
          <div class="nk-invoice-body">
            <div class="table-responsive">
              <table class="table nk-invoice-table">
                <thead class="table-light">
                  <tr>
                    <th class="tb-col">
                      <span class="overline-title">
                        item id
                      </span>
                    </th>
                    <th class="tb-col">
                      <span class="overline-title">
                        description
                      </span>
                    </th>
                    <th class="tb-col">
                      <span class="overline-title">
                        price
                      </span>
                    </th>
                    <th class="tb-col">
                      <span class="overline-title">
                        qty
                      </span>
                    </th>
                    <th class="tb-col tb-col-end">
                      <span class="overline-title">
                        total
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tb-col">
                      <span>
                        24108054
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        Basic Bundle
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        $130.20
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        1
                      </span>
                    </td>
                    <td class="tb-col tb-col-end">
                      <span>
                        $130.20
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="tb-col">
                      <span>
                        24108055
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        6 months premium support
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        $149.99
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        2
                      </span>
                    </td>
                    <td class="tb-col tb-col-end">
                      <span>
                        $289.99
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="tb-col">
                      <span>
                        22108031
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        Enterprise Bundle
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        $596.99
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        1
                      </span>
                    </td>
                    <td class="tb-col tb-col-end">
                      <span>
                        $590.99
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="tb-col">
                      <span>
                        21205495
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        Pro Plan
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        $596.99
                      </span>
                    </td>
                    <td class="tb-col">
                      <span>
                        1
                      </span>
                    </td>
                    <td class="tb-col tb-col-end">
                      <span>
                        $789.99
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">
                    </td>
                    <td colspan="2">
                      Subtotal:
                    </td>
                    <td class="tb-col-end">
                      $1658.40
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                    </td>
                    <td colspan="2">
                      VAT (0%):
                    </td>
                    <td class="tb-col-end">
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                    </td>
                    <td colspan="2">
                      Grand Total:
                    </td>
                    <td class="tb-col-end">
                      $1658.40
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
    </div>
    </div>

    </div>
  );
};

export default Invoice;
