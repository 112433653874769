import React, { useState } from "react";
import Sidebar from "../../components/side_bar";
import Fixed_header from "../../components/fixed_header";

const Product = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [productImage, setProductImage] = useState("");
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleCreate = () => {
    // Add logic to handle product creation
    console.log("Product Created:", { productName, productImage });
    handleClose();
  };

  const handleEditShow = (product) => {
    setCurrentProduct(product);
    setProductName(product.name);
    setProductImage(product.image);
    setShowEditModal(true);
  };
  const handleEditClose = () => setShowEditModal(false);
  const handleEdit = () => {
    // Add logic to handle product update
    console.log("Product Updated:", { productName, productImage });
    handleEditClose();
  };

  const handleDeleteShow = (product) => {
    setCurrentProduct(product);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDelete = () => {
    // Add logic to handle product deletion
    console.log("Product Deleted:", currentProduct);
    handleDeleteClose();
  };

  const handleViewShow = (product) => {
    setCurrentProduct(product);
    setShowViewModal(true);
  };
  const handleViewClose = () => setShowViewModal(false);

  const products = [
    {
      id: 1,
      name: "Mobile",
      image:
        "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Smartphone.png?v=1706646691",
      createDate: "2022-11-25",
      updateDate: "2024-03-05",
    },
    {
      id: 2,
      name: "Tablet",
      image:
        "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/tablet.png?v=1706646659",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 3,
      name: "laptop",
      image:
        "https://t3.ftcdn.net/jpg/01/65/68/40/360_F_165684079_HBdjlE5yM4sbSPBKb0qp0cYSfeKdosYv.jpg",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 4,
      name: "smartwatch",
      image:
        "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/smartwatch.png?v=1706646626",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
    {
      id: 5,
      name: "earbuds",
      image:
        "https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Earpods.png?v=1706645530",
      createDate: "2023-02-18",
      updateDate: "2024-05-10",
    },
  ];

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head-between flex-wrap gap g-2">
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title">Products</h2>
                    </div>
                    <div className="nk-block-head-content">
                      <ul className="d-flex">
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary btn-md d-md-none"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary d-none d-md-inline-flex"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Product</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="nk-block">
                    <div className="card data-table-card">
                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div className="dataTable-top">
                          <div className="dataTable-dropdown">
                            <label>
                              <select className="dataTable-selector">
                                <option value="5">5</option>
                                <option value="10" selected>
                                  10
                                </option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                              </select>
                              Per page
                            </label>
                          </div>
                          <div className="dataTable-search">
                            <input
                              className="dataTable-input"
                              placeholder="Search..."
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="dataTable-container table-responsive">
                          <table
                            className="datatable-init table dataTable-table"
                            data-nk-container="table-responsive"
                          >
                            <thead className="table-light">
                              <tr>
                                <th
                                  className="tb-col tb-col-check"
                                  data-sortable="false"
                                  style={{ width: "5%" }}
                                ></th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "30%" }}
                                >
                                  <span className="overline-title">
                                    Product Logo
                                  </span>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "25%" }}
                                >
                                  <a href="#" className="dataTable-sorter">
                                    <span className="overline-title">
                                      Product Name
                                    </span>
                                  </a>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable=""
                                  style={{ width: "20%" }}
                                >
                                  <a href="#" className="dataTable-sorter">
                                    <span className="overline-title">
                                      Create Date
                                    </span>
                                  </a>
                                </th>
                                <th
                                  className="tb-col"
                                  data-sortable="false"
                                  style={{ width: "20%" }}
                                >
                                  <span className="overline-title">
                                    Update Date
                                  </span>
                                </th>
                                <th
                                  className="tb-col tb-col-end"
                                  data-sortable="false"
                                  style={{ width: "5%" }}
                                >
                                  <span className="overline-title">
                                    Actions
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {products.map((product) => (
                                <tr key={product.id}>
                                  <td className="tb-col tb-col-check">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                      />
                                    </div>
                                  </td>
                                  <td className="tb-col">
                                    <div className="media-group">
                                      <div className="media media-middle">
                                        <img
                                          src={product.image}
                                          alt="product"
                                        />
                                      </div>
                                      <div className="media-text">
                                        <a href="#" className="title"></a>
                                        <div className="d-sm-none">
                                          <span>300</span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="tb-col">
                                    <span className="">
                                      {product.name}
                                    </span>
                                  </td>
                                  <td className="tb-col">
                                    <span>{product.createDate}</span>
                                  </td>
                                  <td className="tb-col">
                                    <span>{product.updateDate}</span>
                                  </td>
                                  <td className="tb-col tb-col-end">
                                    {/* start action option */}
                                    <div className="dropdown">
                                      <a
                                        href="#"
                                        className="btn btn-sm btn-icon btn-zoom me-n1"
                                        data-bs-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                                        <div className="dropdown-content py-1">
                                          <ul
                                            className="link-list link-list-hover-bg-primary link-list-md"
                                            style={{
                                              padding: "5px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <li>
                                              <a
                                                href="#"
                                                onClick={() =>
                                                  handleEditShow(product)
                                                }
                                                style={{ padding: "5px" }}
                                              >
                                                <em
                                                  className="icon ni ni-edit"
                                                  style={{ marginRight: "5px" }}
                                                ></em>
                                                <span>Edit</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#"
                                                onClick={() =>
                                                  handleDeleteShow(product)
                                                }
                                                style={{ padding: "5px" }}
                                              >
                                                <em
                                                  className="icon ni ni-trash"
                                                  style={{ marginRight: "5px" }}
                                                ></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#"
                                                onClick={() =>
                                                  handleViewShow(product)
                                                }
                                                style={{ padding: "5px" }}
                                              >
                                                <em
                                                  className="icon ni ni-eye"
                                                  style={{ marginRight: "5px" }}
                                                ></em>
                                                <span>View</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {/* end action option */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="dataTable-bottom">
                          <div className="dataTable-info">
                            Showing 1 to 10 of 15 entries
                          </div>
                          <nav className="dataTable-pagination">
                            <ul className="dataTable-pagination-list">
                              <li className="active">
                                <a href="#" data-page="1">
                                  1
                                </a>
                              </li>
                              <li className="">
                                <a href="#" data-page="2">
                                  2
                                </a>
                              </li>
                              <li className="pager">
                                <a href="#" data-page="2">
                                  <em className="icon ni ni-chevron-right"></em>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Create Modal */}
                  <div
                    className={`modal fade ${showModal ? "show" : ""}`}
                    style={{ display: showModal ? "block" : "none" }}
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add Product</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="mb-3">
                              <label
                                htmlFor="productName"
                                className="form-label"
                              >
                                Product Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="productName"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="productImage"
                                className="form-label"
                              >
                                Product Logo
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="productImage"
                                onChange={(e) =>
                                  setProductImage(e.target.files[0])
                                }
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleCreate}
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Create Modal */}
                  {/* Edit Modal */}
                  <div
                    className={`modal fade ${showEditModal ? "show" : ""}`}
                    style={{ display: showEditModal ? "block" : "none" }}
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Edit Product</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleEditClose}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="mb-3">
                              <label
                                htmlFor="editProductName"
                                className="form-label"
                              >
                                Product Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="editProductName"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="editProductImage"
                                className="form-label"
                              >
                                Product Logo
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="editProductImage"
                                onChange={(e) =>
                                  setProductImage(e.target.files[0])
                                }
                              />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleEditClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleEdit}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Edit Modal */}
                  {/* Delete Modal */}
                  <div
                    className={`modal fade ${showDeleteModal ? "show" : ""}`}
                    style={{ display: showDeleteModal ? "block" : "none" }}
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Delete Product</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleDeleteClose}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <p>Are you sure you want to delete this product?</p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleDeleteClose}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleDelete}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Delete Modal */}
                  {/* View Modal */}
                  <div
                    className={`modal fade ${showViewModal ? "show" : ""}`}
                    style={{ display: showViewModal ? "block" : "none" }}
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">View Product</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={handleViewClose}
                          ></button>
                        </div>
                        <div
                          className="modal-body"
                          style={{ textAlign: "center" }}
                        >
                          {currentProduct && (
                            <>
                              <img
                                src={currentProduct.image}
                                alt="product"
                                style={{
                                  maxWidth: "200px",
                                  marginBottom: "20px",
                                }}
                              />
                              <h3 style={{ marginBottom: "10px" }}>
                                {currentProduct.name}
                              </h3>
                              <p style={{ marginBottom: "5px" }}>
                                Create Date: {currentProduct.createDate}
                              </p>
                              <p style={{ marginBottom: "20px" }}>
                                Update Date: {currentProduct.updateDate}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handleViewClose}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* End View Modal */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
