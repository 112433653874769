
import Header from './component/header'
import CarouselComponent from './component/caroselcomponent'
import { FaStar } from "react-icons/fa";
import Review from './component/review';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BrandModels.model.css";
import Footer from "./component/footer";

const Home = () => {
    const navigate = useNavigate();
    const [clickedProduct, setClickedProduct] = useState(null);
  
    const products = [
        {
          _id: 1,
          name: "Redmi A3",
          image:
            "https://rukminim2.flixcart.com/image/850/1000/xif0q/mobile/i/h/z/a3-mzb0h0lin-redmi-original-imagy2wsmpetm6s2.jpeg?q=20&crop=false",
        },
        {
          _id: 2,
          name: "Redmi Note 13 Pro+",
          image:
            "https://cdn.quicksell.co/-MSICrgv_CcSTrDyc8-S/products/-Nr89_fLWEhM9mWJ3t4t.jpg",
        },
        {
          _id: 3,
          name: "Redmi Note 13 Pro",
          image:
            "https://www.giztop.com/media/catalog/product/cache/dc206057cdd42d7e34b9d36e347785ca/e/d/edmi_note_13_pro_1.jpg",
        },
        {
          _id: 4,
          name: "Redmi Note 13 5G",
          image: "https://img.myipadbox.com/sec/product_l/EDA005663301A.jpg",
        },
        {
          _id: 5,
          name: "Redmi 13C",
          image:
            "https://dakauf.eu/wp-content/uploads/2024/01/Xiaomi-Redmi-13C-Midnight-Black.jpg",
        },
        {
          _id: 6,
          name: "Redmi 12",
          image:
            "https://rukminim2.flixcart.com/image/850/1000/xif0q/mobile/e/a/g/-original-imags37h4prxjazz.jpeg?q=20&crop=false",
        },
        {
          _id: 7,
          name: "Redmi A2",
          image:
            "https://cdn1.smartprix.com/rx-iFKksZqST-w1200-h1200/FKksZqST.jpg",
        },
        {
          _id: 8,
          name: "Redmi 12 5G",
          image:
            "https://www.giztop.com/media/catalog/product/cache/dc206057cdd42d7e34b9d36e347785ca/r/e/redmi_12_5g-.png",
        },
      ];
      const handleProductClick = (id) => {
        setClickedProduct(id === clickedProduct ? null : id);
        navigate(`/SheduleBooking`);
      };
  return (
    <div id='home'>
      <Header/>
      <CarouselComponent/>


      <div className='container'>
        <div className=' services'>
            <div className='services-com text-center'>
                <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Smartphone.png?v=1706646691'></img>
                <h3>Smartphone repair</h3>
            </div>
            <div className='services-com text-center'>
            <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/tablet.png?v=1706646659'></img>
                <h3>Tablet repair</h3>
            </div>
            <div className='services-com text-center'>
            <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/smartwatch.png?v=1706646626'></img>
                <h3>Smartwatch repair</h3>
            </div>
            <div className='services-com text-center'>
            <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Earpods.png?v=1706645530'></img>
                <h3>Earbud repair</h3>
            </div>
        </div>
      </div>


      <div className='we-fix'>
        <div className='container'>
            <h3 className='section-title'><strong>What Can We Fix?</strong></h3>
            <div className='we-fix-outer row'>
                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Screen.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>
                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Charge.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>

                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Screen.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>

                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Screen.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>
                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Screen.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>
                <div className='col-12 col-md-4 col-sm-6'>
                <div className='we-fix-content'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Screen.png?v=1706623978'></img>
                    <h2>Craked Screen</h2>
                    <p>Get fast and affordable cracked screen replacement services from our expert team. Trust us to fix your device.</p>
                    </div>

                </div>
            </div>
        </div>
      </div>
      <div className='trandy-mobile'>
        <div className='container'>
      <h3 className='section-title'><strong>Model We Fix?</strong></h3>
      <div className="row">
        {products.map((product) => (
          <div
            key={product._id}
            className={`col-md-3 col-sm-6 mb-4 ${
              clickedProduct === product._id ? "clicked" : ""
            }`}
            onClick={() => handleProductClick(product._id)}
          >
            <div
              className={`product_brand-card ${
                clickedProduct === product._id ? "clicked" : ""
              }`}
            >
              <img
                src={product.image}
                className="img-fluid product_brand-image"
                alt={product.name}
              />
              <p className="product_brand-name">{product.name}</p>
            </div>
          </div>
        ))}
      </div>
      </div>
      </div>

      {/* ====================================================== */}


      <div className='futere-text'>
        <div className='container'>
            <div className='row align-items-center flex-md-column-reverse'>
            <div className='col-10 col-md-4 col-sm-6'>
                <div className='futere-para'>
                    <h3><strong>We Can Fix It Almost As Fast As You Can Break It.</strong></h3>
                    <p>We do fast phone repair. In most cases, technicians will be able to repair your device in just minutes, we’ll normally get your phone in and out in 45 minutes or less.</p>
                    <button>About us</button>
                </div>
            </div>
            <div className='col-12 col-md-4 col-sm-6'>
                <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/Untitled-1_d5d6ca47-1201-4069-89ad-16068c5292f8.png?v=1706613748'></img>
            </div>
            </div>
        </div>
      </div>

      {/* ======================================================== */}
      <div className="why-chose-us">
        <div className='container'>
        
            <div className='row flex-md-column ' style={{alignItems: 'center'}}>
                <div className='col-10 col-md-4 col-sm-6'>
                    <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/992871.jpg?v=1706614104'></img>
                </div>
                <div className='col-10 col-md-4 col-sm-6'>
                <h3 className='section-title'><strong><strong>Why Choose Us?</strong></strong></h3>
                <h6>Same Day Repairs</h6>
                <p>When your phone breaks, you don’t want it fixed tomorrow—you need it fixed today.</p>
                <h6>Low Price Guarantee</h6>
                <p>Repairing beats replacing, and FixTeam beats all other phone repair companies.</p>
                <h6>Superior Warranty</h6>
                <p>Stronger than any phone case, FixTeam offers the most reliable warranty on the market.</p>
                </div>
            </div>
        </div>
      </div>
      <Review/>
      <div id='Blog-post'>
        <div className='container'>
            <h3 className='section-title'>
                <strong>Blog posts</strong>
            </h3>
            <div className='row'>
            <div className='col-12 col-md-4 col-sm-6'>
                <div className='card'>
                    <h3>Drenched Phone? Don't Despair! An Expert's Guide to Water Damage Diagnosis and Repair</h3>
                    <p>So, your phone took a plunge. Fear not! While water damage can be nerve-wracking, expert technicians have the tools and know-how to diagnose and repair your beloved device. Let's dive into the process:</p>
                </div>
            </div>
            <div className='col-12 col-md-4 col-sm-6'>
                <div className='card'>
                    <h3>Drenched Phone? Don't Despair! An Expert's Guide to Water Damage Diagnosis and Repair</h3>
                    <p>So, your phone took a plunge. Fear not! While water damage can be nerve-wracking, expert technicians have the tools and know-how to diagnose and repair your beloved device. Let's dive into the process:</p>
                </div>
            </div>

            </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
