import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { BsCart3 } from "react-icons/bs";
import { IoReorderThreeOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div id='header'>
      <div className='container'>
       <div className='row align-items-center justify-content-between'>
        <div className='col-6 col-md-2 col-sm-6'>
        <Link to="/">
           <img style={{width: '100%'}} src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/logo-_New2.png?v=1706531905' alt="Logo" />
           </Link>
        </div>
        <div className='col-8 d-none d-md-block'>
            <div className='header-menu'>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/appointment">Book an appointment</Link></li>
              </ul>
            </div>
        </div>
        <div className='header-icon col-2 d-none d-md-block'>
          <Link><IoIosSearch /></Link>
          <Link to="/login"><FaRegUserCircle /></Link>
          <Link><BsCart3 /></Link>
        </div>
        <div className='mobile-menu-btn' onClick={toggleMenu}>
          <IoReorderThreeOutline />
        </div>
       </div>
      </div>
      <div className={`mobile-menu ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className='mobile-menu-outer'>
          <div className='close-menu' onClick={toggleMenu}><IoClose /></div>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/appointment">Book an appointment</Link></li>
          </ul>
          <div className='mebile-lion-btm'>
          <Link to="/login">login</Link>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Header;
