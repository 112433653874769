import React from 'react'
import { FaStar } from 'react-icons/fa'

const review = () => {
  return (
    <div id="review">
        <div className='container'>
            <div className='review-heading'>
                <h3>Excellent</h3>
                <div className='starts-review'>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                </div>
<div className='row review-item-outer'>
    <div className='col-12 col-md-4 col-sm-6'>
        <div className='review-item'>
            <div className='review-item-person-info'>
                <img src="https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/e76d4296-43f3-493b-9d50-f8e5c142d06c/2117667014/boys-profile-picture-screenshot.png"/>
                            <div><h3>rohan</h3>
                            <p>junvery 2023</p>
                            </div>
                
                
                
                </div>
                <div className='stars-review-content'>
                <div className='costumers-review'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                
                </div>

            </div>
            
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
        <div className='review-item'>
            <div className='review-item-person-info'>
                <img src="https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/e76d4296-43f3-493b-9d50-f8e5c142d06c/2117667014/boys-profile-picture-screenshot.png"/>
                            <div><h3>rohan</h3>
                            <p>junvery 2023</p>
                            </div>
                
                
                
                </div>
                <div className='stars-review-content'>
                <div className='costumers-review'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                
                </div>

            </div>
            
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
        <div className='review-item'>
            <div className='review-item-person-info'>
                <img src="https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/e76d4296-43f3-493b-9d50-f8e5c142d06c/2117667014/boys-profile-picture-screenshot.png"/>
                            <div><h3>rohan</h3>
                            <p>junvery 2023</p>
                            </div>
                
                
                
                </div>
                <div className='stars-review-content'>
                <div className='costumers-review'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                
                </div>

            </div>
            
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
        <div className='review-item'>
            <div className='review-item-person-info'>
                <img src="https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/e76d4296-43f3-493b-9d50-f8e5c142d06c/2117667014/boys-profile-picture-screenshot.png"/>
                            <div><h3>rohan</h3>
                            <p>junvery 2023</p>
                            </div>
                
                
                
                </div>
                <div className='stars-review-content'>
                <div className='costumers-review'>
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                
                </div>

            </div>
            
        </div>
        
    </div>
    

</div>
            </div>
        </div>
      
  )
}

export default review
