import React from 'react'
import Header from './component/header'
import phone from "../img/Mobile-Phone-PNG-HD.png"
import { FaMobileScreen } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Footer from './component/footer';

const Order = () => {
  return (
    <div id='order'>
    <Header/>
    <div className='order'>
    <div className='container'>
    <h3 className='section-title'>
        <strong>Pick your service</strong>
    </h3>
        <div className='order-outer'>
        <div className='order-contents'></div>
            <div className='row'>
                <div className='col-4'>
                    <img style={{width: '105px'}} src={phone}></img>
                </div>
                <div className='col-8'>
                    <h4>Vivo V3</h4>
                </div>
            </div>
            <div className='order-srvices'>
            <h5>Pick your repair service</h5>
                <div className='row'>
                    <div className='col-12 row ordr-service-content'>
                        <div className='col-6'>
                            <FaMobileScreen /> Screen
                        </div>
                        <div className='col-6 text-end'>
                       <p>₹ 2000</p>  <Link>add</Link>
                        </div>
                    </div>
                    <div className='col-12 row ordr-service-content'>
                        <div className='col-6'>
                            <FaMobileScreen /> Screen
                        </div>
                        <div className='col-6 text-end'>
                       <p>₹ 2000</p>  <Link>add</Link>
                        </div>
                    </div>

                    <div className='col-12 row ordr-service-content'>
                        <div className='col-6'>
                            <FaMobileScreen /> Screen
                        </div>
                        <div className='col-6 text-end'>
                       <p>₹ 2000</p>  <Link>add</Link>
                        </div>
                    </div>

                    <div className='col-12 row ordr-service-content'>
                        <div className='col-6'>
                            <FaMobileScreen /> Screen
                        </div>
                        <div className='col-6 text-end'>
                       <p>₹ 2000</p>  <Link>add</Link>
                        </div>
                    </div>

                    
                </div>
                
            </div>
            <div className='appontment-btn'>
                    <Link>Book Appointment</Link>
                </div>
        </div>
    </div>
    </div>
      <Footer/>
    </div>
  )
}

export default Order
