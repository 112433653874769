import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ScheduleBooking.css';
import Header from './component/header';
import Footer from './component/footer';

const ScheduleBooking = () => {
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    pickupLocation: '',
    problem: '',
    product: '',
    model: '',
    brand: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add form submission logic here
  };

  return (
    <div>
      <Header />
      <h2 className="text-center man_heading"><b>Schedule Booking</b></h2>
      <div className="booking-container d-flex align-items-center justify-content-center">
        <div className="booking-card">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input type="date" className="form-control" id="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="time">Time</label>
              <input type="time" className="form-control" id="time" name="time" value={formData.time} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="pickupLocation">Pickup Location</label>
              <input type="text" className="form-control" id="pickupLocation" name="pickupLocation" placeholder="Enter pickup location" value={formData.pickupLocation} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="problem">Select Problem</label>
              <select className="form-control" id="problem" name="problem" value={formData.problem} onChange={handleChange} required>
                <option value="">Select Problem</option>
                <option value="battery">Battery Issue</option>
                <option value="screen">Screen Issue</option>
                <option value="charging">Charging Issue</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="product">Product</label>
              <input type="text" className="form-control" id="product" name="product" placeholder="Enter product" value={formData.product} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="model">Model</label>
              <input type="text" className="form-control" id="model" name="model" placeholder="Enter model" value={formData.model} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="brand">Brand</label>
              <input type="text" className="form-control" id="brand" name="brand" placeholder="Enter brand" value={formData.brand} onChange={handleChange} required />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Submit</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScheduleBooking;
