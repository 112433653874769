import React from 'react'
import Footer from './component/footer'
import Header from './component/header'

const Contact_us = () => {
  return (
    <div id='Contact_us'>
    <Header/>

    <div className='appointment'>
    <div className='container'>
        <h3 className='section-title'>
            <strong>about us</strong>
        </h3>
        </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Contact_us
