import React, { useState, useEffect } from "react";
import Sidebar from "../../components/side_bar";
import Fixed_header from "../../components/fixed_header";
import axios from "axios";

const Admin_Brand = () => {
  const [showModal, setShowModal] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [brandImage, setBrandImage] = useState(null);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://treadgroceryservices.shop/admin/phone_banao/get_brands.php");
      setBrands(response.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching brands. Please try again later.");
      setLoading(false);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleFileChange = (e) => {
    setBrandImage(e.target.files[0]);
  };

  const handleCreate = async () => {
    const formData = new FormData();
    formData.append("brandName", brandName);
    formData.append("brandImage", brandImage);

    try {
      const response = await axios.post("https://treadgroceryservices.shop/admin/phone_banao/save_brand.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Brand Created:", response.data);
      alert("Brand Created:", response.data);
      handleClose();
      fetchBrands();
    } catch (error) {
      console.error("There was an error creating the brand!", error);
      alert("There was an error creating the brand! " + error.message);
    }
  };

  const handleDelete = async (brandId) => {
    try {
      const response = await axios.delete('https://treadgroceryservices.shop/admin/phone_banao/delete_brand.php', {
        data: { brand_id: brandId }
      });
      console.log(response.data);
      if (response.data.success) {
        setBrands(brands.filter(brand => brand.brand_id !== brandId));
      } else {
        console.error("Error deleting brand:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting brand:", error);
      alert("Error deleting brand:", error);
    }
  };
  
  
  

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div className="container">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head-between flex-wrap gap g-2">
                    <div className="nk-block-head-content">
                      <h2 className="nk-block-title">Brands</h2>
                    </div>
                    <div className="nk-block-head-content">
                      <ul className="d-flex">
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary btn-md d-md-none"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add</span>
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleShow}
                            className="btn btn-primary d-none d-md-inline-flex"
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Brand</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Add Modal */}
                  {showModal && (
                    <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Create Brand</h5>
                            <button type="button" className="close" onClick={handleClose}>
                              <span>&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group">
                              <label>Brand Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={brandName}
                                onChange={(e) => setBrandName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label>Brand Image</label>
                              <input
                                type="file"
                                className="form-control"
                                onChange={handleFileChange}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleCreate}>
                              Create
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* End of Add Modal */}

                  {/* Display Brands */}
                  <div className="dataTable-container table-responsive">
                    <table className="datatable-init table dataTable-table" data-nk-container="table-responsive">
                      <thead className="table-light">
                        <tr>
                          <th className="tb-col" data-sortable="" style={{width: '25.5735%'}}>
                            <a href="#" className="dataTable-sorter">
                              <span className="overline-title">
                                Brands
                              </span>
                            </a>
                          </th>
                          <th className="tb-col tb-col-end" data-sortable="false" style={{width: ' 9.26083%'}}>
                            <span className="overline-title">
                              Action
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {brands.map((brand) => (
                          <tr key={brand.brand_id}>
                            <td className="tb-col">
                              <div className="media-group">
                                <div className="media media-md media-middle media-circle">
                                  <img src={`https://treadgroceryservices.shop/admin/phone_banao/${brand.image}`} alt="Brand Image" />
                                </div>
                                <div className="media-text">
                                  <span className="title">{brand.name}</span>
                                </div>
                              </div>
                            </td>
                            <td className="tb-col tb-col-end">
                              <button className="btn btn-danger" onClick={() => handleDelete(brand.brand_id)}>{brand.brand_id}Delete</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_Brand;
