import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
 // Make sure to create this component or import it if it's defined elsewhere
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/home';
import Brands from './pages/brands';
import Appointment from './pages/appointment';
import Order from './pages/order';
import About_us from './pages/about-us';
import Faq from './pages/faq';
import Contact_us from './pages/contact_us';
import Blog from './pages/blog';
import Admin_dashboard from './pages/admin/admin_dashboard';
import Users from './pages/admin/user';
import Product from './pages/admin/product';
import Brand from './pages/admin/brand';
import Invoice from './pages/admin/invoice';
import Staff from './pages/admin/staff';
import LoginModal from './pages/LoginModal';
import BrandModels from './pages/BrandModels';
import SheduleBooking from './pages/SheduleBooking';
import UserSignup from './pages/UserSignup';
import UserLogin from './pages/UserLogin';
import Admin_login from './pages/admin/admin_login';
import Add_Model from './pages/admin/add_model';
import Model from './pages/admin/model';
import Admin_Brand from './pages/admin/admin_brand';


function App() {
  return (
    <BrowserRouter>
      <div className='margin'></div>
      <Routes>
        {/* Define routes */}
        <Route path="/" element={<Home />} />
        <Route path='/brands' element={<Brands/>}/>
        <Route path='/LoginModal' element={<LoginModal/>}/>
        <Route path='/brand_model' element={<BrandModels/>}/>
        <Route path='/appointment' element={<Appointment/>}/>
        <Route path='/signup' element={<UserSignup/>}/>
        <Route path='/login' element={<UserLogin/>}/>
        <Route path='/SheduleBooking' element={<SheduleBooking/>}/>
        <Route path='/order' element={<Order/>}/>
        <Route path='/About-us' element={<About_us/>}/>
        <Route path='/Faq' element={<Faq/>}/>
        <Route path='/contact_us' element={<Contact_us/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/admin' element={<Admin_dashboard/>}/>
        <Route path='/users' element={<Users/>}/>
        <Route path='/product' element={<Product/>}/>
        <Route path='/brand' element={<Brand/>}/>
        <Route path='/invoice' element={<Invoice/>}/>
        <Route path='/staff' element={<Staff/>}/>
        <Route path='/admin_login' element={<Admin_login/>}/>
        <Route path='/add_model' element={<Add_Model/>}/>
        <Route path='/model' element={<Model/>}/>
        <Route path='/Admin_brand' element={<Admin_Brand/>}/>


        {/* Add more routes here as needed */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
