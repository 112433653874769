import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./UserLogin.css";
import Header from "./component/header";
import Footer from "./component/footer";

function UserSignup() {
  const [username, setUsername] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://treadgroceryservices.shop/admin/phone_banao/signup.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          phone_no,
          address,
          password,
          repeatPassword,
          termsAccepted,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "success") {
        setSuccess(data.message);
        setError("");
        window.location.href = '/user/login';
      } else {
        setError(data.message);
        setSuccess("");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("Failed to submit form. Please try again later.");
    }
  };

  return (
    <div>
      <Header/>
      <div className="container" style={{marginTop: '0px'}}>
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img src='https://www.yaantra.com/images/in-30min.png' alt="Side Image" className="side-image" />
          </div>
          <div className="col-md-6 signup-form">
            <center><h1 className="mb-4">Sign Up</h1></center>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone_no">Phone No</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone_no"
                  value={phone_no}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="repeatPassword">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="repeatPassword"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </div>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="terms">
                  I agree to the Terms of Use
                </label>
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Sign Up
              </button>
            </form>
            <div className="mt-3">
              <p>I have an account go <Link to="/login" className="text-secondary">Sign In</Link></p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default UserSignup;
