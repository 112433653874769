import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const Daily_user_registered = () => {
  const [dailyData, setDailyData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchDailyData = async () => {
      try {
        const response = await fetch("https://app.karmchari.in/api/admin/usery");
        if (!response.ok) {
          throw new Error('Failed to fetch daily data');
        }
        const data = await response.json();
        setDailyData(data);
      } catch (error) {
        console.error('Error fetching daily data:', error);
      }
    };

    fetchDailyData();
  }, []);

  useEffect(() => {
    if (dailyData.length === 0) return;

    // Extract x (day) and y (value) from daily data
    const labels = dailyData.map(entry => entry.day);
    const values = dailyData.map(entry => entry.value);

    // Destroy existing chart instance if it exists
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }

    // Creating the chart
    const ctx = document.getElementById('myChart');
    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [{
          label: 'Number of Registrations',
          backgroundColor: "rgb(0, 123, 255)",
          data: values
        }]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Day of the Month'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Number of Registrations'
            },
            beginAtZero: true
          }
        }
      }
    });
    
    // Clean up function
    return () => {
      if (chartRef.current !== null) {
        chartRef.current.destroy();
      }
    };
  }, [dailyData]);

  return (
    <div>
      <canvas id="myChart" width="400" height="200"></canvas>
    </div>
  );
};

export default Daily_user_registered;
