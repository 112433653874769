import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BrandModels.model.css";
import Header from "./component/header";
import Footer from "./component/footer";
const BrandModels = () => {
  const navigate = useNavigate();
  const [clickedProduct, setClickedProduct] = useState(null);

  const products = [
    {
      _id: 1,
      name: "Redmi A3",
      image:
        "https://rukminim2.flixcart.com/image/850/1000/xif0q/mobile/i/h/z/a3-mzb0h0lin-redmi-original-imagy2wsmpetm6s2.jpeg?q=20&crop=false",
    },
    {
      _id: 2,
      name: "Redmi Note 13 Pro+",
      image:
        "https://cdn.quicksell.co/-MSICrgv_CcSTrDyc8-S/products/-Nr89_fLWEhM9mWJ3t4t.jpg",
    },
    {
      _id: 3,
      name: "Redmi Note 13 Pro",
      image:
        "https://www.giztop.com/media/catalog/product/cache/dc206057cdd42d7e34b9d36e347785ca/e/d/edmi_note_13_pro_1.jpg",
    },
    {
      _id: 4,
      name: "Redmi Note 13 5G",
      image: "https://img.myipadbox.com/sec/product_l/EDA005663301A.jpg",
    },
    {
      _id: 5,
      name: "Redmi 13C",
      image:
        "https://dakauf.eu/wp-content/uploads/2024/01/Xiaomi-Redmi-13C-Midnight-Black.jpg",
    },
    {
      _id: 6,
      name: "Redmi 12",
      image:
        "https://rukminim2.flixcart.com/image/850/1000/xif0q/mobile/e/a/g/-original-imags37h4prxjazz.jpeg?q=20&crop=false",
    },
    {
      _id: 7,
      name: "Redmi A2",
      image:
        "https://cdn1.smartprix.com/rx-iFKksZqST-w1200-h1200/FKksZqST.jpg",
    },
    {
      _id: 8,
      name: "Redmi 12 5G",
      image:
        "https://www.giztop.com/media/catalog/product/cache/dc206057cdd42d7e34b9d36e347785ca/r/e/redmi_12_5g-.png",
    },
  ];

  const handleProductClick = (id) => {
    setClickedProduct(id === clickedProduct ? null : id);
    navigate(`/SheduleBooking`);
  };

  return (
    <div>
      <Header/>
    <div className="container_brand my-5">
      
      <div className="row">
        {products.map((product) => (
          <div
            key={product._id}
            className={`col-md-3 col-sm-6 mb-4 ${
              clickedProduct === product._id ? "clicked" : ""
            }`}
            onClick={() => handleProductClick(product._id)}
          >
            <div
              className={`product_brand-card ${
                clickedProduct === product._id ? "clicked" : ""
              }`}
            >
              <img
                src={product.image}
                className="img-fluid product_brand-image"
                alt={product.name}
              />
              <p className="product_brand-name">{product.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default BrandModels;
