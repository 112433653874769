import React, { useEffect, useState } from "react";
import Sidebar from "../../components/side_bar";
import Fixed_header from "../../components/fixed_header";
import axios from "axios";
import { Button } from "@mui/material";
const Users = () => {
  const [users, setUsers] = useState([]);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    // Get the components of the date (year, month, day)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    // Return the formatted date string (YYYY-MM-DD)
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    // Fetch users when the component mounts
    axios
      .get("https://treadgroceryservices.shop/admin/phone_banao/fatch_user.php")
      .then((response) => {
        if (response.data.status === "success") {
          setUsers(response.data.data);
        } else {
          console.error("Failed to fetch users:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the users:", error);
      });
  }, []);
  return (
    <div class="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          {/* ==================================================================== */}
          <Fixed_header />

          {/* =================================================================================== */}
          <div class="nk-content">
            <div class="container">
              <div class="nk-content-inner">
                <div class="nk-content-body">
                  <div class="nk-block-head">
                    <div class="nk-block-head-between flex-wrap gap g-2">
                      <div class="nk-block-head-content">
                        <h2 class="nk-block-title">Users List</h2>

                        <nav>
                          <ol class="breadcrumb breadcrumb-arrow mb-0">
                            <li class="breadcrumb-item">
                              <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                              <a href="#">User Manage</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              Users
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div class="nk-block-head-content"></div>
                    </div>
                  </div>
                  <div class="nk-block">
                    <div class="card">
                      <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                        <div class="dataTable-top">
                          <div class="dataTable-dropdown">
                            <label>
                              <select class="dataTable-selector">
                                <option value="5">5</option>
                                <option value="10" selected="">
                                  10
                                </option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                              </select>
                              Per page
                            </label>
                          </div>
                          <div class="dataTable-search">
                            <input
                              class="dataTable-input"
                              placeholder="Search..."
                              type="text"
                            />
                          </div>
                        </div>

                        <div class="dataTable-container table-responsive">
                          <table
                            class="datatable-init table dataTable-table"
                            data-nk-container="table-responsive"
                          >
                            <thead class="table-light">
                              <tr>
                                <th
                                  class="tb-col"
                                  data-sortable=""
                                  style={{ width: "25.5735%" }}
                                >
                                  <a href="#" class="dataTable-sorter">
                                    <span class="overline-title">Users</span>
                                  </a>
                                </th>
                                <th
                                  class="tb-col"
                                  data-sortable=""
                                  style={{ width: "13.5939%" }}
                                >
                                  <a href="#" class="dataTable-sorter">
                                    <span class="overline-title">Phone no</span>
                                  </a>
                                </th>
                                <th
                                  class="tb-col"
                                  data-sortable=""
                                  style={{ width: " 10.5353%" }}
                                >
                                  <a href="#" class="dataTable-sorter">
                                    <span class="overline-title">Address</span>
                                  </a>
                                </th>
                                <th
                                  class="tb-col tb-col-xl"
                                  data-sortable=""
                                  style={{ width: " 14.6134%" }}
                                >
                                  <a href="#" class="dataTable-sorter">
                                    <span class="overline-title">
                                      Joined Date
                                    </span>
                                  </a>
                                </th>

                                <th
                                  class="tb-col"
                                  data-sortable=""
                                  style={{ width: " 11.2999%" }}
                                >
                                  <a href="#" class="dataTable-sorter">
                                    <span class="overline-title">Status</span>
                                  </a>
                                </th>
                                <th
                                  class="tb-col tb-col-end"
                                  data-sortable="false"
                                  style={{ width: " 9.26083%" }}
                                >
                                  <span class="overline-title">Action</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.map((user, index) => (
                                <tr>
                                  <td class="tb-col">
                                    <div class="media-group">
                                      <div class="media media-md media-middle media-circle">
                                        <img
                                          src="https://html.nioboard.themenio.com/images/avatar/a.jpg"
                                          alt="user"
                                        />
                                      </div>
                                      <div class="media-text">
                                        <a
                                          href="https://html.nioboard.themenio.com/user-manage/user-profile.html"
                                          class="title"
                                        >
                                          {user.username}
                                        </a>
                                        {/* <span class="small text">
                              florenza@gmail.com
                            </span> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td class="tb-col">{user.phone_no}</td>
                                  <td class="tb-col">{user.address}</td>
                                  <td class="tb-col tb-col-xl">
                                    {formatDate(user.time_stamp)}
                                  </td>

                                  <td class="tb-col">
                                    <span class="badge text-bg-warning-soft">
                                      Pending
                                    </span>
                                  </td>
                                  <td class="tb-col tb-col-end">
                                    <Button variant="outlined" color="error">
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div class="dataTable-bottom">
                          <div class="dataTable-info">
                            Showing 1 to 10 of 24 entries
                          </div>
                          <nav class="dataTable-pagination">
                            <ul class="dataTable-pagination-list">
                              <li class="active">
                                <a href="#" data-page="1">
                                  1
                                </a>
                              </li>
                              <li class="">
                                <a href="#" data-page="2">
                                  2
                                </a>
                              </li>
                              <li class="">
                                <a href="#" data-page="3">
                                  3
                                </a>
                              </li>
                              <li class="pager">
                                <a href="#" data-page="2">
                                  <em class="icon ni ni-chevron-right"></em>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
