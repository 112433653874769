import React, { useState } from "react";
import Sidebar from "../../components/side_bar";
import Fixed_header from "../../components/fixed_header";
import axios from "axios";
import { CiCirclePlus } from "react-icons/ci";
import { CiImageOn } from "react-icons/ci";

const Add_Model = () => {
  const [rowCount, setRowCount] = useState(1);
  const [modelData, setModelData] = useState({
    model_name: "",
    brand: "",
    category: "",
    problems: [],
    model_image: null,
  });

  const addRow = () => {
    setRowCount(rowCount + 1);
  };

  const addProblem = () => {
    setModelData({
      ...modelData,
      problems: [...modelData.problems, { problem_name: "", price: "" }],
    });
  };

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setModelData({
      ...modelData,
      [name]: value,
    });
  };

  const handleProblemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedProblems = [...modelData.problems];
    if (!updatedProblems[index]) {
      updatedProblems[index] = { problem_name: "", price: "" };
    }
    updatedProblems[index][name] = value;
    setModelData({ ...modelData, problems: updatedProblems });
  };

  const handleImageChange = (event) => {
    setModelData({ ...modelData, model_image: event.target.files[0] });
  };

  const saveModel = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData();
    formData.append("model_name", modelData.model_name);
    formData.append("brand", modelData.brand);
    formData.append("category", modelData.category);
    formData.append("model_image", modelData.model_image);

    modelData.problems.forEach((problem, index) => {
      formData.append(`problems[${index}][problem_name]`, problem.problem_name);
      formData.append(`problems[${index}][price]`, problem.price);
    });

    axios
      .post("https://treadgroceryservices.shop/admin/phone_banao/save_model.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Fixed_header />
          <div className="nk-content">
            <div class="container">
              <div class="nk-content-inner">
                <div class="nk-content-body">
                  <div class="nk-block-head">
                    <div class="nk-block-head-between flex-wrap gap g-2">
                      <div class="nk-block-head-content">
                        <h2 class="nk-block-title">Add Model</h2>
                        <nav>
                          <ol class="breadcrumb breadcrumb-arrow mb-0">
                            <li class="breadcrumb-item">
                              <a href="#">Home</a>
                            </li>
                            <li class="breadcrumb-item">
                              <a href="#">ecommerce</a>
                            </li>
                            <li
                              class="breadcrumb-item active"
                              aria-current="page"
                            >
                              Add Product
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div class="nk-block">
                    <form action="#">
                      <div class="row g-gs">
                        <div class="col-xxl-9">
                          <div class="gap gy-4">
                            <div class="gap-col">
                              <div class="card card-gutter-md">
                                <div class="card-body">
                                  <div class="row g-gs">
                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label
                                          for="model_name"
                                          class="form-label"
                                        >
                                          Model Name
                                        </label>
                                        <div class="form-control-wrap">
                                          <input
                                            type="text"
                                            class="form-control"
                                            id="model_name"
                                            name="model_name"
                                            placeholder="Model Name"
                                            value={modelData.model_name}
                                            onChange={handleModelChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label
                                          for="brand"
                                          class="form-label"
                                        >
                                          Brand
                                        </label>
                                        <div class="form-control-wrap">
                                          <input
                                            type="text"
                                            class="form-control"
                                            id="brand"
                                            name="brand"
                                            placeholder="Brand"
                                            value={modelData.brand}
                                            onChange={handleModelChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label
                                          for="category"
                                          class="form-label"
                                        >
                                          Category
                                        </label>
                                        <div class="form-control-wrap">
                                          <input
                                            type="text"
                                            class="form-control"
                                            id="category"
                                            name="category"
                                            placeholder="Category"
                                            value={modelData.category}
                                            onChange={handleModelChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="gap-col mt-5">
                            <div class="card card-gutter-md">
                              <div class="card-body">
                                <label>Problems</label>
                                {/* Repeat the row based on rowCount state */}
                                {[...Array(rowCount)].map((_, index) => (
                                  <div
                                    key={index}
                                    className="row mt-2"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div class="col-6">
                                      <div class="form-group">
                                        <div class="form-control-wrap">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Problem Name"
                                            name="problem_name"
                                            value={
                                              modelData.problems[index]
                                                ? modelData.problems[index]
                                                    .problem_name
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleProblemChange(index, e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      <div class="form-group">
                                        <div class="form-control-wrap">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Price"
                                            name="price"
                                            value={
                                              modelData.problems[index]
                                                ? modelData.problems[index]
                                                    .price
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleProblemChange(index, e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <button
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                  }}
                                  onClick={addRow}
                                >
                                  <CiCirclePlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="gap-col mt-5">
                            <div class="card card-gutter-md">
                              <div class="card-body">
                                <div class="form-group">
                                  <label class="form-label">Upload Media</label>
                                  <div
                                    class="form-control-wrap"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      class="js-upload dropzone dz-clickable"
                                      id="dropzoneFile1"
                                      data-message-icon="img"
                                    >
                                      <div
                                        class="dz-message"
                                        data-dz-message=""
                                      >
                                        <div class="dz-message-icon">
                                          <CiImageOn />
                                          <input
  type="file"
  style={{
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "0",
    right: "0",
    top: "0",
    opacity: "0",
  }}
  onChange={handleImageChange}
/>

                                        </div>
                                        <span class="dz-message-text">
                                          Drop files here or click to upload.
                                        </span>
                                        <div class="dz-message-btn mt-2">
                                          <button class="btn btn-md btn-primary">
                                            Upload
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button onClick={saveModel}>
                                    Save Model
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add_Model;
