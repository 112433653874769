import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div id='footer'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-4 col-sm-6'>
        <img style={{width: '100%'}} src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/logo-_New2.png?v=1706531905'></img>
        <h5>PhoneBanao - We Fix It & We Fix It Fast.</h5>
        <div className='row'>
            <div className='col-4'><Link><FaInstagram /></Link></div>
            <div className='col-4'><Link><FaFacebook /></Link></div>
            <div className='col-4'><Link><FaTwitter /></Link></div>
        </div>
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
            <div className='footer-link'>
            <h6>Quick links</h6>
                <ul>
                    <li><Link to="/About-us">About Us</Link></li>
                    <li><Link to="/faq">FAQ's</Link></li>
                    <li><Link to="/contact_us">Contact</Link></li>
                    <li><Link to="/blog"> Blog</Link></li>
                </ul>
            </div>
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
        <div className='footer-link'>
            <h6>Repair Services</h6>
                <ul>
                    <li><Link>Smartphones</Link></li>
                    <li><Link>Tablets</Link></li>
                    <li><Link>Smartwatch</Link></li>
                    <li><Link> Earpod</Link></li>
                </ul>
            </div>
        </div>
        <div className='col-12 col-md-4 col-sm-6'>
            <img src='https://cdn.shopify.com/s/files/1/0563/0790/8671/files/qr-code.png?v=1706654150'></img>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer
